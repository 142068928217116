import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { HOMPEPAGE_CLIENT, UPDATE_HOMEPAGE_CLIENT } from 'gql';
import { GET_HOMEPAGE } from 'gql/server/homepage';
import { useCheckLogin } from './useCheckLogin';
import { useUpdateMeClient } from './useUpdateMeClient';
import { organizationEvent } from 'modules/google_tag_manager';
import {
  getLocalStorage,
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
  setDataLocalStorage,
  setDataSessionStorage,
} from 'share/utils';
import {
  KEY_LOCAL_ASSIGNED_SURVEYS,
  KEY_SESSION_ORGANIZATION_ID,
  ORG_LOGO,
} from 'CONST';
import { useCustomPermission } from './useCustomPermission';

export const useUpdateHomepageClient = () => {
  const isLogin = useCheckLogin();

  const { isSuperAdmin, isAdmin } = useCustomPermission();

  const { meClient } = useUpdateMeClient();

  const remoteUser = JSON.parse(getLocalStorage('remoteUser')!);

  const [
    updateHompeageClient,
    { loading: loadingUpdateHomepageClient },
  ] = useMutation(UPDATE_HOMEPAGE_CLIENT);

  const [getHomepage] = useLazyQuery(GET_HOMEPAGE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      handleUpdateHomepageClient(data);

      if (data && data?.getHomePageConfig?.organization.name) {
        var obj: any = {
          id: data?.getHomePageConfig?.organization._id,
          name: data?.getHomePageConfig?.organization.name,
        };
        if (isLogin) {
          var middleName = meClient?.middleName ? meClient?.middleName : '';
          var lastName = meClient?.lastName! ? meClient?.lastName : '';
          obj['userId'] = meClient?._id;
          obj['userName'] =
            meClient?.firstName + ' ' + middleName + ' ' + lastName;
        }
        organizationEvent(obj);
      }
      if (
        (!isLogin && data && data?.getHomePageConfig?.organization._id) ||
        isSuperAdmin ||
        isAdmin
      ) {
        setDataSessionStorage(
          KEY_SESSION_ORGANIZATION_ID,
          data?.getHomePageConfig?.organization._id,
        );
      }

      if (data?.getHomePageConfig?.logoURL) {
        setDataLocalStorage(ORG_LOGO, data?.getHomePageConfig?.logoURL);
      } else {
        removeLocalStorage(ORG_LOGO);
      }

      assignedSurveys();
    },
    onError() {},
  });

  const { data: dataHomepageClient } = useQuery(HOMPEPAGE_CLIENT);

  const handleUpdateHomepageClient = (dataUpdate?: any) => {
    const homepageData = dataUpdate?.getHomePageConfig;

    const variables = {
      _id: homepageData._id,
      buttonConfigurations: homepageData?.buttonConfigurations,
      dynamicTreatments: homepageData?.dynamicTreatments,
      footerConfigurations: homepageData?.footerConfigurations,
      organization: homepageData?.organization,
      organizationHomepageSlug: homepageData?.organizationHomepageSlug,
      logoURL: homepageData?.logoURL,
      __typename: 'homepageClient',
    };

    updateHompeageClient({
      variables,
    });
  };

  const assignedSurveys = () => {
    if (isLogin) {
      var assignedSurveys = JSON.parse(
        getLocalStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
      removeSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS);
    } else {
      var assignedSurveys = JSON.parse(
        getSessionStorage(KEY_LOCAL_ASSIGNED_SURVEYS)!,
      );
    }
    if (assignedSurveys != null && assignedSurveys.length > 0) {
      assignedSurveys.filter((survey: any) => {
        if (new Date() < new Date(survey.expiredDate)) return survey;
      });
      if (isLogin) {
        setDataLocalStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      } else {
        setDataSessionStorage(
          KEY_LOCAL_ASSIGNED_SURVEYS,
          JSON.stringify(assignedSurveys),
        );
      }
    }
  };

  const handleGetHomepage = () => {
    getHomepage({
      variables: {
        params: {
          organization:
            isSuperAdmin && remoteUser
              ? remoteUser?.organization?.idOrg
              : getSessionStorage(KEY_SESSION_ORGANIZATION_ID)
              ? getSessionStorage(KEY_SESSION_ORGANIZATION_ID)
              : meClient?.organizationId.length > 0
              ? meClient?.organizationId
              : null,
        },
      },
    });
  };

  return {
    handleGetHomepage,
    homepageClient: dataHomepageClient?.homepageClient,
    loadingUpdateHomepageClient,
    handleUpdateHomepageClient,
  };
};
