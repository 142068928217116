import gql from 'graphql-tag';

export const MetaFragment = gql`
  fragment Meta on MetaRes {
    totalPage
    limit
    page
    total
  }
`;
export const SurveyFragment = gql`
  fragment Survey on Treatment {
    idSurvey: _id
    name
    surveyData
    order
    createdAt
    isPrivate
    forOSCProcedures
  }
`;

export const OwnerFragment = gql`
  fragment Owner on User {
    idOwner: _id
    firstName
    middleName
    lastName
  }
`;
export const OwnerListFragment = gql`
  fragment OwnerList on User {
    _id
  }
`;

export const SubTreatmentDefaultFragment = gql`
  fragment SubTreatmentDefault on Treatment {
    _id
    mainImage
    mainVideo
    name
    description
    shortDescription
    createdAt
    thumbnail
    isSurveyInline
    smsMessage
  }
`;

export const TreatmentDefaultFragment = gql`
  fragment TreatmentDefault on Treatment {
    _id
    mainImage
    mainVideo
    bannerImage
    name
    description
    shortDescription
    createdAt
    thumbnail
    isSurveyInline
    smsMessage
  }
`;

export const UserFragment = gql`
  fragment User on User {
    _id
    email
    firstName
    middleName
    lastName
    fullName
    role
    organization {
      idOrg: _id
      name
    }
    dob
    mrn
    phones
    status
    isNoEmail
    authenticationInfo {
      authenticationSource
    }
  }
`;

export const DuplicateUserFragment = gql`
  fragment DuplicateUser on User {
    _id
    email
    firstName
    lastName
    middleName
    dob
    phones
  }
`;

export const UserListFragment = gql`
  fragment UserList on MeRes {
    _id
    email
    firstName
    lastName
    fullName
    middleName
    role
    phones
    isBlankAccount
    organization {
      idOrg: _id
      name
    }
    patient {
      organization {
        idPatientOrg: _id
        name
      }
    }
    status
    authenticationInfo {
      authenticationSource
    }
    secondaryEmail {
      email
      isVerified
    }
    isBlankAccount
  }
`;

export const TreatmentStageFragment = gql`
  fragment TreatmentStage on TreatmentStage {
    stageId: _id
    name
    linkTo {
      treatmentId: _id
      name
    }
  }
`;

export const TreatmentStagesFragment = gql`
  fragment TreatmentStages on TreatmentStageEmbed {
    name
    treatmentStage {
      ...TreatmentStage
    }
  }
  ${TreatmentStageFragment}
`;

export const SubTreatmentDetailFragment = gql`
  fragment SubTreatmentDetail on Treatment {
    subId: _id
    name
    description
    shortDescription
    content
    thumbnail
    mainVideo
    createdAt
    mainImage
    order
    stageLinkTo {
      _id
      name
    }
    owner {
      ...Owner
    }
    survey {
      ...Survey
    }
    subSections {
      name
      description
      shortDescription
      content
      thumbnail
      mainVideo
      createdAt
      mainImage
      slug
    }
    slug
    qrCode
    urlSharing
    smsMessage
  }
  ${SurveyFragment}
  ${OwnerFragment}
`;

export const RelatedArticlesFragment = gql`
  fragment RelatedArticles on RelatedArticle {
    _id
    name
    description
    shortDescription
    content
    thumbnail
    mainVideo
    mainImage
    slug
    smsMessage
    qrCode
    urlSharing
    survey {
      ...Survey
    }
  }
  ${SurveyFragment}
`;

export const TreatmentFragment = gql`
  fragment Treatment on Treatment {
    ...TreatmentDefault
    isRoot
    parentId
    qrCode
    urlSharing
    subSectionIds
    version
    subSections {
      ...SubTreatmentDetail
    }
    surveyData
    survey {
      ...Survey
    }
    owner {
      ...Owner
    }
    treatmentStages {
      ...TreatmentStages
    }
    stageLinkTo {
      treatment {
        treatmentStages {
          ...TreatmentStages
        }
      }
    }
    slug
    favorites {
      _id
    }
  }
  ${TreatmentDefaultFragment}
  ${SurveyFragment}
  ${OwnerFragment}
  ${SubTreatmentDetailFragment}
  ${TreatmentStagesFragment}
`;

export const SubTreatmentFragment = gql`
  fragment SubTreatment on Treatment {
    order
    owner {
      ...Owner
    }
    survey {
      ...Survey
    }
    ...SubTreatmentDefault
    slug
    qrCode
    urlSharing
  }
  ${OwnerFragment}
  ${SubTreatmentDefaultFragment}
  ${SurveyFragment}
`;

export const TreatmentDetailFragment = gql`
  fragment TreatmentDetail on Treatment {
    ...TreatmentDefault
    content
    isRoot
    slug
    parentId
    qrCode
    urlSharing
    hideMainMedia
    pdfLink
    owner {
      ...Owner
    }
    contributorIds
    subSections {
      ...SubTreatmentDetail
    }
    survey {
      ...Survey
    }
    treatmentStages {
      ...TreatmentStages
    }
    organization {
      _id
    }
    relatedArticles {
      ...RelatedArticles
    }
  }
  ${OwnerFragment}
  ${TreatmentDefaultFragment}
  ${SurveyFragment}
  ${TreatmentStagesFragment}
  ${SubTreatmentDetailFragment}
  ${RelatedArticlesFragment}
`;

export const ResponseModifyTreatmentFragment = gql`
  fragment ResponseModifyTreatment on Treatment {
    _id
    name
    parentId
    parent {
      _id
      name
      survey {
        _id
        name
        surveyData
        smsMessage
      }
      type
    }
    ownerId
    version
    type
    order
    createdAt
    checklist {
      idChecklist: _id
    }
    smsMessage
  }
`;

export const TreatmentTreeFragment = gql`
  fragment TreatmentTree on Treatment {
    ...ResponseModifyTreatment
    qrCode
    urlSharing
    checklist {
      idChecklist: _id
      name
      ownerId
      contributorIds
    }
    isReference
    reference {
      idReference: _id
    }
    rootRef {
      idRootRef: _id
    }
    contributorIds
    favorites {
      idUser: _id
    }
    isHideOnHomePage
    slug
    isDefault
    isPrivate
    forOSCProcedures
    tags {
      tag {
        _id
      }
      name
      isAssignTag
    }
    survey {
      _id
      name
      surveyData
      smsMessage
    }
  }
  ${ResponseModifyTreatmentFragment}
`;

export const TreatmentForAddUpdateFragment = gql`
  fragment TreatmentForAddUpdate on Treatment {
    _id
    name
    type
  }
`;

export const ResponseRemoteLoginFragment = gql`
  fragment ResponseRemoteLogin on User {
    _id
    email
    firstName
    lastName
    middleName
    role
    phones
    organization {
      idOrg: _id
      name
      defaultTreatment {
        idTreatment: _id
      }
      timezone {
        value
        offset
        label
      }
    }
    authenticationInfo {
      authenticationSource
    }
    secondaryEmail {
      email
      isVerified
    }
    setting {
      patientDashboard {
        sort
      }
      analyticDashboard {
        dateRange
      }
      homePage {
        treatmentType
      }
      perPage
    }
  }
`;

export const ResponseLoginFragment = gql`
  fragment ResponseLogin on MeRes {
    _id
    email
    firstName
    lastName
    middleName
    role
    phones
    organization {
      idOrg: _id
      name
      defaultTreatment {
        idTreatment: _id
      }
      timezone {
        offset
        value
        label
      }
    }
    authenticationInfo {
      authenticationSource
    }
    patient {
      idPatient: _id
      organization {
        idOrg: _id
        name
      }
    }
    secondaryEmail {
      email
      isVerified
    }
    setting {
      patientDashboard {
        sort
      }
      analyticDashboard {
        dateRange
      }
      homePage {
        treatmentType
      }
      leftDrawer {
        treatmentType
        showInDrawer
      }
      perPage
    }
    remoteUser {
      ...ResponseRemoteLogin
    }
  }
  ${ResponseRemoteLoginFragment}
`;

export const DashboardFragment = gql`
  fragment Dashboard on Dashboard {
    _id
    name
    charts {
      chartId: _id
      dataSource
      reportType
      chartConfig
    }
  }
`;

export const DashboardChartOrderedFragment = gql`
  fragment DashboardChartOrdered on ChartConfig {
    chartId: _id
    dataSource
    reportType
    chartConfig
    order
    grid
    isCopy
    displayName
    surveyVersion
  }
`;

export const SurveyVersionFragment = gql`
  fragment SurveyVersion on SurveyVersion {
    _id
    surveyData
    originSurvey
    version
    emrObx
    name
    smsMessage
    checklist {
      _id
      name
      ownerId
    }
  }
`;

export const DashboardResFragment = gql`
  fragment DashboardRes on DashboardRes {
    _id
    name
    isShow
    chartsOrdered {
      ...DashboardChartOrdered
    }
  }
  ${DashboardChartOrderedFragment}
`;

export const TagFragment = gql`
  fragment Tag on Tag {
    _id
    name
    color
  }
`;

export const TagInfoFragment = gql`
  fragment TagInfo on Tag {
    _id
    name
    color
    createdAt
  }
`;

export const PatientTagFragment = gql`
  fragment PatientTag on AddTagRes {
    tag {
      ...Tag
    }
    added
    isColorChanged
  }
  ${TagFragment}
`;

export const TagEmbeddedFragment = gql`
  fragment TagEmbeddedOnPatient on TagEmbedded {
    tag {
      _id
    }
  }
`;

export const PatientOwnerFragment = gql`
  fragment PatientOwner on Patient {
    idOwner: _id
    firstName
    lastName
    middleName
    email
    mrn
    dob
    emrProvider {
      _id
      emrProvider
    }
    provider {
      _id
      provider
      emrProvider
    }
    phones
    tags {
      ...TagEmbeddedOnPatient
    }
    organization {
      name
    }
    patientConfig {
      contactConfig {
        method
      }
    }
    user {
      idUser: _id
      status
    }
    gender
    race
    address
    status
  }
  ${TagEmbeddedFragment}
`;

export const SubNoteFragment = gql`
  fragment SubNote on NoteEmbedded {
    idNote: _id
    description
    notedAt
    status
    createdAt
    updatedAt
    color
    type {
      idType: _id
      color
      name
    }
  }
`;

export const PatientNoteFragment = gql`
  fragment PatientNote on PatientNote {
    _id
    createdAt
    surveyResult {
      surveyResultId: _id
      createdAt
    }
    survey {
      surveyId: _id
      name
    }
    notes {
      ...SubNote
    }
  }
  ${SubNoteFragment}
`;

export const PatientFollowUpFragment = gql`
  fragment PatientFollowUp on PatientFollowUp {
    _id
    note
    status
    followUpDate
    createdAt
    updatedAt
    type {
      _id
      description
      name
      color
    }
  }
`;

export const SurveyResultEmbeddedFragment = gql`
  fragment SurveyResultEmbedded on SurveyResultEmbedded {
    _id
    createdAt
    updatedAt
    updatedBy {
      firstName
      lastName
      middleName
    }
    answerDateTime
    data
    patientNote {
      ...PatientNote
    }
    surveyDetail {
      surveyId: _id
      name
      version
      surveyData
      originSurvey
    }
    workingStatus
    isArchive
  }
  ${PatientNoteFragment}
`;

export const PatientSurveyFragment = gql`
  fragment PatientSurvey on SurveyResultGroupRes {
    smsMessage
    name
    version
    surveyResult {
      ...SurveyResultEmbedded
    }
    updatedAt
  }
  ${SurveyResultEmbeddedFragment}
`;

export const SurveyForNoteFragment = gql`
  fragment SurveyForNote on SurveyResultGroupRes {
    idSurvey: _id
    name
    version
    survey {
      name
      version
    }
    surveyResult {
      patientNote {
        _id
      }
      surveyResultId: _id
      createdAt
    }
  }
`;

export const SurveyPatientDashboardFragment = gql`
  fragment SurveyPatientDashboard on Treatment {
    _id
    name
    parentId
    surveyData
    version
  }
`;

export const SurveyResultOnPatientFragment = gql`
  fragment SurveyResultOnPatient on PatientDataRes {
    _id
    result
    survey
    createdAt
    updatedAt
    updatedBy {
      updatedById: _id
      firstName
      lastName
    }
  }
`;

// export const SurveyDataByPatientFragment = gql`
//   fragment SurveyDataByPatient on SurveyResultRes {
//     _id
//     createdAt
//     data
//     updatedBy {
//       _id
//       firstName
//       lastName
//     }
//     updatedAt
//   }
// `;

export const CheckHightLightFragment = gql`
  fragment CheckHightLight on TreatmentStage {
    _id
    linkTo {
      _id
    }
  }
`;

export const StageChangeLogFragment = gql`
  fragment StageChangeLogF on StageChangedLog {
    _id
    startDate
    endDate
    createdAt
    root {
      idRoot: _id
      name
    }
    treatment {
      _id
      name
      treatmentStages {
        ...TreatmentStages
      }
      checklist {
        checklistId: _id
        name
      }
    }
    treatmentStageEmbed {
      name
      treatmentStage {
        _id
      }
    }
    status
  }
  ${TreatmentStagesFragment}
`;

export const PatientChecklistSurveyFragment = gql`
  fragment PatientChecklistSurvey on PatientChecklistMono {
    _id
    patientChecklists {
      checklist {
        surveyData
      }
      checklistAvailable {
        surveyData
      }
      checklistResult
      resultChecklist {
        _id
        status
        checklistResult
        updatedAt
      }
    }
  }
`;

export const PatientChecklistEmbeddedFragment = gql`
  fragment PatientChecklistEmbedded on PatientChecklistEmbedded {
    patientChecklistId: _id
    checklist {
      _id
      name
      surveyData
    }
    checklistAvailable {
      _id
      name
      surveyData
    }

    resultChecklist {
      _id
      status
      checklistResult
      updatedAt
      createdAt
    }
  }
`;
export const PatientChecklistPdfFragment = gql`
  fragment PatientChecklistPdf on PatientChecklistMono {
    idChecklist: _id
    treatment {
      name
    }
    survey {
      _id
      name
    }
    updatedAt
    patientChecklists {
      patientChecklistId: _id
      checklist {
        _id
        name
        surveyData
      }
      checklistAvailable {
        _id
        name
        surveyData
      }
      status
      checklistResult
      resultChecklist {
        _id
        status
        checklistResult
        updatedAt
      }
    }
  }
`;

export const PatientChecklistFragment = gql`
  fragment PatientChecklist on PatientChecklistMono {
    patientChecklistMonoId: _id
    treatment {
      name
    }
    survey {
      name
    }
    updatedAt
    patientChecklists {
      ...PatientChecklistEmbedded
    }
  }
  ${PatientChecklistEmbeddedFragment}
`;

export const PatientFragment = gql`
  fragment Patient on PatientRes {
    _id
    owner {
      ...PatientOwner
    }
    data {
      ...SurveyResultOnPatient
    }
  }
  ${PatientOwnerFragment}
  ${SurveyResultOnPatientFragment}
`;

export const PatientAssignFragment = gql`
  fragment PatientAssign on PatientRes {
    _id
    owner {
      idOwner: _id
      firstName
      lastName
      email
      phones
      status
    }
  }
`;

export const PatientDataFragment = gql`
  fragment PatientData on Patient {
    _id
    firstName
    lastName
    email
    mrn
    dob
    phones
    tags {
      ...TagEmbeddedOnPatient
    }
    user {
      userId: _id
      isNoEmail
    }
    organization {
      name
    }
  }
  ${TagEmbeddedFragment}
`;

export const CommentFragment = gql`
  fragment Comment on CommentExt {
    _id
    parentId
    message
    numberOfAnswer
    createdAt
    owner {
      idOwner: _id
      firstName
      lastName
      middleName
      status
    }
    numberOfUnreadAnswer
    readBy {
      readById: _id
    }
  }
`;

export const CommentEdgeFragment = gql`
  fragment CommentEdge on CommentEdge {
    node {
      ...Comment
    }
  }
  ${CommentFragment}
`;

export const GroupCommentsFragment = gql`
  fragment GroupComments on CommentGroupRes {
    _id
    treatment {
      _id
      name
      contributorIds
      ownerId
    }
    unreadCount
    lastCommentAt
  }
`;

export const CurrentStageFragment = gql`
  fragment CurrentStage on CurrentStageRes {
    _id
    rootTreatment {
      idRoot: _id
      name
      createdAt
      treatmentStages {
        ...TreatmentStages
      }
    }
    currentTreatment {
      _id
    }
    status
    stage {
      name
      treatmentStage {
        _id
      }
    }
    assignedTreatment {
      idAssignTreatment: _id
      name
    }
  }
  ${TreatmentStagesFragment}
`;

export const SurveyForAddSurveyFragment = gql`
  fragment SurveyForAddSurvey on Treatment {
    idSurvey: _id
    name
    surveyData
    smsMessage
  }
`;

export const TreatmentsForAddSurveyFragment = gql`
  fragment TreatmentsForAddSurvey on Treatment {
    idTreatment: _id
    name
    survey {
      ...SurveyForAddSurvey
    }
    type
  }
  ${SurveyForAddSurveyFragment}
`;

export const CommonTreatmentFragment = gql`
  fragment CommonTreatment on Treatment {
    createdAt
    _id
    name
    smsMessage
    type
    survey {
      ...SurveyForAddSurvey
    }
    parent {
      type
    }
    mainImage
    description
    forOSCProcedures
  }
  ${SurveyForAddSurveyFragment}
`;

export const GroupCommentByPatientFragment = gql`
  fragment GroupCommentByPatient on CommentGroupRes {
    _id
    user {
      idUser: _id
      firstName
      lastName
      status
    }
    unreadCount
    lastCommentAt
  }
`;

export const ResponseShareTreatmentResFragment = gql`
  fragment ResponseShareTreatmentRes on ShareTreatmentRes {
    user {
      email
      id
      phones
      sendEmail
      sendSMS
    }
    treatment
    result
    msg
    userError
  }
`;

export const NotificationFragment = gql`
  fragment Notification on Notification {
    _id
    receiver {
      _id
      fullName
    }
    seen
    msg
    treatment {
      _id
      name
    }
    createdAt
  }
`;

export const FollowUpTypeSettingFragment = gql`
  fragment FollowUpTypeSetting on FollowUpTypeSetting {
    _id
    name
    color
    createdAt
    description
    treatment {
      _id
      name
    }
    folder {
      _id
      name
    }
    favorites {
      idUser: _id
    }
  }
`;

export const FollowUpTypeShortFragment = gql`
  fragment FollowUpTypeShort on FollowUpTypeSetting {
    _id
    name
    description
  }
`;

export const TreatmentFromDeleteFragment = gql`
  fragment TreatmentFromDelete on TreatmentsAssignedRes {
    _id
    numberOfPatient
    treatment {
      idTreatment: _id
      name
    }
  }
`;

export const PatientNoteTypeSettingFragment = gql`
  fragment PatientNoteTypeSetting on PatientNoteTypeSetting {
    _id
    name
    description
    createdAt
    color
    treatments {
      _id
      name
    }
    folder {
      _id
      name
    }
  }
`;

export const GroupTreatmentSettingFragment = gql`
  fragment GroupTreatmentSetting on GroupTreatmentSetting {
    _id
    name
    description
    treatment {
      ...CommonTreatment
    }
  }
  ${CommonTreatmentFragment}
`;

export const GroupTreatmentSettingAssignFragment = gql`
  fragment GroupTreatmentSettingAssign on GroupTreatmentSetting {
    _id
    name
    description
    treatment {
      ...CommonTreatment
      createdAt
    }
  }
  ${CommonTreatmentFragment}
`;

export const StageConditionFragment = gql`
  fragment StageEmbedded on StageEmbedded {
    _id
    name
  }
`;

export const ConditionSettingFragment = gql`
  fragment ConditionSetting on ConditionSetting {
    _id
    name
    stages {
      ...StageEmbedded
    }
    createdAt
  }
  ${StageConditionFragment}
`;

export const ProviderSettingFragment = gql`
  fragment ProviderSetting on ProviderSetting {
    _id
    provider
    emrProvider
    description
  }
`;

export const ConditionSettingForModifyFragment = gql`
  fragment ConditionSettingForModify on ConditionSetting {
    _id
    name
  }
`;

export const PatientCondStageFragment = gql`
  fragment PatientCondStage on PatientCondStage {
    _id
    condition {
      conditionId: _id
      name
      stages {
        stageId: _id
        name
      }
    }
    color
    createdAt
    startDate
    endDate
    stageId
  }
`;

export const FolderForTaskTypeFragment = gql`
  fragment FolderForTaskType on Treatment {
    ...CommonTreatment
    subSections {
      _id
      type
    }
    parentId
  }
  ${CommonTreatmentFragment}
`;

export const PathToTreatmentFragment = gql`
  fragment PathToTreatment on TreatmentsPathRes {
    idPath: _id
    name
    slug
    mainImage
    mainVideo
    shortDescription
    subSections {
      ...SubTreatmentDetail
    }
  }
  ${SubTreatmentDetailFragment}
`;

export const SamePatientFragment = gql`
  fragment SamePatient on User {
    idUser: _id
    email
    firstName
    lastName
    mrn
    dob
  }
`;

export const SameNewPatientFragment = gql`
  fragment SameNewPatient on Patient {
    idUser: _id
    email
    firstName
    lastName
    middleName
    gender
    dob
    phones
  }
`;

export const OrganizationFragment = gql`
  fragment Organization on Organization {
    _id
    name
    description
    default
    inboundPath
    outboundPath
    defaultTreatment {
      name
    }
    timezone {
      offset
      value
      label
    }
  }
`;

export const PatientDashboardInfoFragment = gql`
  fragment PatientDashboardInfo on PatientDashboardInfoRes {
    _id
    name
    config
    condition
    isQuickView
  }
`;
export const PatientDashboardInfoQuickViewFragment = gql`
  fragment PatientDashboardInfoQuickView on PatientDashboardInfoRes {
    _id
    name
    numberOfPatient
  }
`;

export const EmrLogFragment = gql`
  fragment EmrLog on EmrLog {
    _id
    fileName
    status
    fieldNote
    message
    createdAt
    patient
    newFileName
  }
`;

export const TwilioLogFragment = gql`
  fragment TwilioLog on TwilioLog {
    _id
    status
    error
    body
    phoneNumber
    createdAt
  }
`;

export const ReleaseNoteSettingFragment = gql`
  fragment ReleaseNoteSetting on ReleaseNoteSetting {
    _id
    version
    releaseDate
    releaseNote
  }
`;

export const AssignTreatmentResFragment = gql`
  fragment AssignTreatmentRes on AssignTreatmentRes {
    _id: treatmentId
    token
    slug
    organization
    orgName
    treatmentName
  }
`;

export const ScheduleConfigEmbeddedFragment = gql`
  fragment ScheduleConfigEmbedded on ScheduleConfigEmbedded {
    freq
    dtstart
    until
    count
    interval
    byweekday
    byNweekDay {
      weekday
      n
    }
    bymonth
    bymonthday
  }
`;

export const CalendarConfigFragment = gql`
  fragment CalendarConfig on CalendarConfig {
    _id
    sendEmail
    sendSMS
    smsMessage
    sendAppointmentEmail
    sendAppointmentSMS
    apptTime
    procedure
    patient {
      _id
      firstName
    }
    task {
      _id
      name
      color
      description
      status
      createdAt
    }
    taskDesc
    survey {
      _id
      parentId
      name
      parent {
        _id
        name
      }
      type
    }
    scheduleConfig {
      ...ScheduleConfigEmbedded
    }
    configType
    treatmentRegimenId
    regimenStart
    lastSendTime
    time {
      hour
      minute
    }
    sendAppointmentEmail
    sendAppointmentSMS
    apptTime
    procedure
  }
  ${ScheduleConfigEmbeddedFragment}
`;

export const RegimenConfigEmbeddedFragment = gql`
  fragment RegimenConfigEmbedded on RegimenConfigEmbedded {
    treatment {
      _id
      name
      type
      ownerId
      smsMessage
    }
    task {
      _id
      name
      color
    }
    taskDesc
    updatedBy {
      _id
    }
    time {
      hour
      minute
    }
    specifiedTime
    treatmentType
    specifiedType
    scheduleConfig {
      ...ScheduleConfigEmbedded
    }
  }
  ${ScheduleConfigEmbeddedFragment}
`;

export const TreatmentRegimenFragment = gql`
  fragment TreatmentRegimen on TreatmentRegimen {
    _id
    regimenName
    regimenConfig {
      ...RegimenConfigEmbedded
    }
    owner {
      ...User
    }
    before
    forProcedures
  }
  ${UserFragment}
  ${RegimenConfigEmbeddedFragment}
`;

export const TreatmentRegimenAddPatientFragment = gql`
  fragment TreatmentRegimenAddPatient on TreatmentRegimen {
    _id
    name: regimenName
    regimenConfig {
      treatment {
        ...CommonTreatment
      }
      task {
        _id
        name
        createdAt
      }
      treatmentType
    }
  }
  ${CommonTreatmentFragment}
`;

export const UpdateTagTreatmentTreeEmbeddedFragment = gql`
  fragment UpdateTagTreatmentTreeEmbedded on UpdateTagTreatmentTreeEmbedded {
    tag {
      ...Tag
    }
    name
    isAssignTag
  }
  ${TagFragment}
`;

export const CPTCodeFragment = gql`
  fragment CPTCode on CPTCode {
    _id
    name
    code
    desc
    patientNoteTypes {
      ...PatientNoteTypeSetting
    }
  }
  ${PatientNoteTypeSettingFragment}
`;

export const PatientCPTCodeFragment = gql`
  fragment PatientCPTCode on PatientCPTCode {
    _id
    cptCode {
      ...CPTCode
    }
    patient {
      _id
    }
    owner {
      ...Owner
    }
    state
    startedAt
    stoppedAt
  }
  ${CPTCodeFragment}
  ${OwnerFragment}
`;

export const PatientTimeReportFragment = gql`
  fragment PatientTimeReport on PatientTimeReportRes {
    _id
    owner {
      ...Owner
      role
    }
    startedAt
    stoppedAt
    second
    status
    state
    patientNoteTypes {
      id {
        _id
      }
      description
    }
    patientNoteTypeSettings {
      _id
      name
      color
    }
  }
  ${OwnerFragment}
`;

export const PatientTimeRecordFragment = gql`
  fragment PatientTimeRecord on PatientTimeRecordRes {
    _id
    patient
    code
    desc
    totalSecond
    lastStartedAt
    lastStoppedAt
    timeReport {
      ...PatientTimeReport
    }
  }
  ${PatientTimeReportFragment}
`;

export const SaveRecordingFragment = gql`
  fragment SaveRecording on TSaveRecording {
    _id
    startedAt
    stoppedAt
    idPatient
    idCptCode
  }
`;

export const QROptionsFragment = gql`
  fragment QROptions on QROptions {
    typeNumber
    mode
    errorCorrectionLevel
  }
`;

export const ImageOptionsFragment = gql`
  fragment ImageOptions on ImageOptions {
    hideBackgroundDots
    imageSize
    margin
  }
`;

export const GradientFragment = gql`
  fragment Gradient on Gradient {
    type
    rotation
    colorStops {
      offset
      color
    }
  }
`;

export const DotsOptionsFragment = gql`
  fragment DotsOptions on DotsOptions {
    type
    color
    gradient {
      ...Gradient
    }
  }
  ${GradientFragment}
`;

export const BackgroundOptionsFragment = gql`
  fragment BackgroundOptions on BackgroundOptions {
    color
    gradient {
      ...Gradient
    }
  }
  ${GradientFragment}
`;

export const CornersSquareOptionsFragment = gql`
  fragment CornersSquareOptions on CornersSquareOptions {
    type
    color
    gradient {
      ...Gradient
    }
  }
  ${GradientFragment}
`;

export const CornersDotOptionsFragment = gql`
  fragment CornersDotOptions on CornersDotOptions {
    type
    color
    gradient {
      ...Gradient
    }
  }
  ${GradientFragment}
`;

export const ReadabilityScoresFragment = gql`
  fragment ReadabilityScores on ReadabilityScores {
    dalleChallScore
    autoreadabilityScore
    smogScore
    freGrade
    collieu
    linearWrite
    gunningFog
    polySyllable
    fleshGrade
  }
`;

export const UploadedPDFFragment = gql`
  fragment UploadedPDF on UploadedPDF {
    _id
    pdf_name
    conversion_status
    conversion_type
    batchId
    time_took_to_convert
    total_tokens_used
    number_of_pages
    content_of_pages {
      page_no
      content
      tokens_per_page
      imagePath
    }
    sub_docs {
      name
      content
    }
    pdf_url
    fullmarkdown
  }
`;
