import {
  ReportType,
  FollowUpStatus,
  DateFormat,
  ChecklistStatus,
  ChangingBehavior,
  CompletedStatus,
  SurveyResultStatus,
  UserRole,
  Gender,
  Race,
  UserStatus,
  DataSource,
  ConfigAnalyticData,
  HomePageConfig,
  SpecifiedType,
  TreatmentType,
  ConversionType,
  ConversionStatus,
  FormStatus,
} from 'types.d';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';
import orange from '@material-ui/core/colors/orange';
export const DEFAULT_IMAGE = `${process.env.PUBLIC_URL}/default-image.jpg`;
export const DEFAULT_THUMBNAIL_VIDEO = `${process.env.PUBLIC_URL}/default-video-thumbnail.png`;
export const DEFAULT_AVATAR = `${process.env.PUBLIC_URL}/default-avatar.jpg`;
export const LIST_IMAGE_EXTENSTION = ['jpeg', 'jpg', 'png'];
export const LIST_VIDEO_EXTENSION = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'qt',
  'flv',
  'swf',
  'avchd',
];

export const rolePermission: { [key: string]: string[] } = {
  ADMIN: [
    'viewPatient',
    'viewHome',
    'viewUser',
    'addUser',
    'updateUser',
    'viewDashboard',
    'viewMedia',
    'uploadMdia',
    'deleteMedia',
    'createTreatment',
    'updateTreatment',
    'deleteTreatment',
    'viewDetailTreatment',
    'viewTreatments',
    'viewSubTreatments',
    'viewComments',
    'viewQuickView',
    'viewCheckList',
    'viewFollowUpType',
    'viewSetting',
    'viewNoteType',
    'viewTreatmentGroup',
    'viewCondition',
    'viewProvider',
    'viewGroupUsers',
    'setTreatmentDefault',
    'viewTreatmentDefault',
    'viewEmrLog',
    'viewTwilioLog',
    'viewOrgInfo',
    'viewShortLink',
    'viewCommonConfigs',
    'viewReleaseNotes',
    'viewAdministration',
    'viewCalendar',
    'viewSettingGroup',
    'viewSettingPathway',
    'viewPDFConversion',
    'viewChatAnalysis',
    'viewTag',
    'viewModules',
    'viewWorkTypes',
  ],
  SUPER_ADMIN: [
    'viewHome',
    'viewUser',
    'addUser',
    'updateUser',
    'viewDetailTreatment',
    'viewAdministration',
    'viewOrganizations',
    'viewGroupUsers',
    'deleteUser',
    'viewTreatments',
    'viewEmrLog',
    'viewShortLink',
    'viewReleaseNotes',
    'viewEmrLogAdministration',
    'viewReleaseNotesAdministration',
  ],
  NAVIGATOR: [
    'viewPatient',
    'viewHome',
    'viewDashboard',
    'viewMedia',
    'uploadMdia',
    'deleteMedia',
    'createTreatment',
    'updateTreatment',
    'deleteTreatment',
    'viewDetailTreatment',
    'viewTreatments',
    'viewSubTreatments',
    'viewComments',
    'viewCheckList',
    'viewQuickView',
    'viewFollowUpType',
    'viewProvider',
    'viewTreatmentGroup',
    'viewShortLink',
    'viewCommonConfigs',
    'viewTwilioLog',
    'viewReleaseNotes',
    'viewSetting',
    'viewTreatmentGroupSetting',
    'viewProviderSetting',
    'viewFollowUpTypeSetting',
    'viewCalendar',
    'viewSettingGroupSetting',
    'viewSettingTreatmentPathway',
    'viewSettingPDFConversion',
    'viewSettingChatAnalysis',
    'viewTagSetting',
    'viewModules',
    'viewWorkTypes',
  ],
  USER: [
    'viewHome',
    'viewDetailTreatment',
    'viewAssignedTreatment',
    'viewComments',
    'viewCheckList',
    'viewShortLink',
    'viewReleaseNotesUser',
  ],
};

export const user = 'USER';
export const navigator = 'NAVIGATOR';
export const superAdmin = 'SUPER_ADMIN';
export const admin = 'ADMIN';

export const LIST_MEDIA_TYPE = [
  { value: 'all', label: 'All' },
  { value: 'image', label: 'Image' },
  { value: 'video', label: 'Video' },
];

export const LIST_STATUS: { [key: string]: string } = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Inactive]: 'Inactive',
  [UserStatus.Pending]: 'Pending',
};

export const DATE_CONFIG: { [key: string]: string } = {
  [ConfigAnalyticData.LastSixMonths]: 'last6Months',
  [ConfigAnalyticData.LastMonth]: 'lastMonth',
  [ConfigAnalyticData.LastWeek]: 'lastWeek',
};

export const LIST_ROLE: {
  [key: string]: { title: string; listRoleAvailable: UserRole[] };
} = {
  [UserRole.SuperAdmin]: {
    title: 'SUPER ADMIN',
    listRoleAvailable: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Navigator,
      UserRole.User,
    ],
  },
  [UserRole.Admin]: {
    title: 'ADMIN',
    listRoleAvailable: [UserRole.Admin, UserRole.Navigator],
  },
  [UserRole.Navigator]: {
    title: 'NAVIGATOR',
    listRoleAvailable: [UserRole.Navigator, UserRole.User],
  },
  [UserRole.User]: { title: 'USER', listRoleAvailable: [] },
};

export const MAX_FILE_SIZE_IMAGE = 5 * 1048576;
export const MAX_FILE_SIZE_VIDEO = 100 * 1048576;
export const KEY_NOT_EXIST = ['isMongoId', 'IsCustomExists'];
export const KEY_LOCAL_ASSIGN_TREATMENT = 'treatment-assign';
export const KEY_LOCAL_ASSIGN_FLOW = 'flow-assign';
export const KEY_OPEN_SYNC_TREATMENT = 'assign-treatment-sync';
export const KEY_LOCAL_SKIP_BANNER = 'local-skip-banner';
export const KEY_LOCAL_NO_LOG_KEY = 'nologkey';
export const KEY_SESSION_ORGANIZATION_ID = 'organization-id';
export const KEY_LOCAL_ASSIGNED_SURVEYS = 'assigned-surveys';
export const CONTEXT_TREATMENTS = 'context-treatments';
export const SHOW_FORMS = 'show-forms';
export const WIDTH_TITLE_TREE = 350;
export const WIDTH_STEP = 20;
export const AI_PLACEHOLDER = "Ask me anything...I'm here to help ";
export const AI_PLACEHOLDER_DELAY_BEFORE_RESTART = 60000;
export const DEFAULT_VOICE_SETTINGS = 'voice-settings';
export const ORG_LOGO = 'org-logo';

//list condition by souce when create chart
export const CONDITION_BY_SOURCE: {
  [key: string]: { [key: string]: string }[];
} = {
  USER: [
    { value: ReportType.UserRole, label: 'Number of users based on user type' },
    {
      value: ReportType.UserAuthenticationSource,
      label: 'Number of users based on authentication source',
    },
    {
      value: ReportType.RegisteredUser,
      label: 'Number of register users based on time',
    },
  ],
  TREATMENT: [
    {
      value: ReportType.TreatmentCreated,
      label: 'Number of created treatments base on time',
    },
    {
      value: ReportType.Top_10TreatmentOwner,
      label: 'Top ten treatments based on owner',
    },
  ],
  COMMENT: [
    {
      value: ReportType.TotalCommentAskedAndReplied,
      label: 'Comments and Replies',
    },
  ],
};

export const LABEL_CHART_BY_CONDITION: {
  [key: string]: { label: string; labelOfValue: string };
} = {
  REGISTERED_USER: {
    label: 'Number of register users based on time',
    labelOfValue: 'Number of users',
  },
  USER_ROLE: {
    label: 'Number of users based on user type',
    labelOfValue: 'Number of users',
  },
  USER_AUTHENTICATION_SOURCE: {
    label: 'Number of users based on authentication source',
    labelOfValue: 'Number of users',
  },
  TREATMENT_CREATED: {
    label: 'Number of created treatments base on time',
    labelOfValue: 'Number of treatment',
  },
  TOP_10_TREATMENT_OWNER: {
    label: 'Top ten treatments base on owner',
    labelOfValue: 'Number of treatments',
  },
  SURVEY: {
    label: '',
    labelOfValue: 'Number of answers',
  },
  TEXT: {
    label: '',
    labelOfValue: 'Answers',
  },
  TOTAL_COMMENT_ASKED_AND_REPLIED: {
    label: 'Comments and Replies',
    labelOfValue: 'Number of comments',
  },
  PATIENT: {
    label: 'Survey Result',
    labelOfValue: 'From email',
  },
  TOTAL_PATIENT_DO_SURVEY_BY_SOURCE: {
    label: 'Survey Result',
    labelOfValue: 'From email',
  },
};

export const TYPE_QUESTION = [
  'rating',
  'comment',
  'text',
  'checkbox',
  'radiogroup',
  'dropdown',
  'boolean',
  'imagepicker',
  'multipletext',
];

export const OBJ_OPERATOR: { [key: string]: string } = {
  equal: '$eq',
  greater: '$gt',
  less: '$lt',
  lessEqual: '$lte',
  greaterEqual: '$gte',
};

export const FOLLOW_UP_STATUS = [
  { valueEnum: 'OPEN', value: FollowUpStatus.Open, title: 'Open' },
  { valueEnum: 'CLOSED', value: FollowUpStatus.Closed, title: 'Closed' },
  {
    valueEnum: 'CANCELLED',
    value: FollowUpStatus.Cancelled,
    title: 'Cancelled',
  },
  {
    valueEnum: 'COMPLETED',
    value: FollowUpStatus.Completed,
    title: 'Completed',
  },
  {
    valueEnum: 'NO RESPONSE',
    value: FollowUpStatus.NoResponse,
    title: 'No response',
  },
  { valueEnum: 'RETRY-1', value: FollowUpStatus.Retry_1, title: 'Retry 1' },
  { valueEnum: 'RETRY-2', value: FollowUpStatus.Retry_2, title: 'Retry 2' },
  { valueEnum: 'RETRY-3', value: FollowUpStatus.Retry_3, title: 'Retry 3' },
  { valueEnum: 'RETRY-4', value: FollowUpStatus.Retry_4, title: 'Retry 4' },
  { valueEnum: 'RETRY-5', value: FollowUpStatus.Retry_5, title: 'Retry 5' },
];
export const KEY_FOR_GET_FILTER = [
  'treatment',
  'followUpDate',
  'status',
  'dob',
  'firstName',
  'lastName',
  'email',
  'phone',
  'mrn',
  'assignDate',
  'assignStatus',
  'tag',
  'checklist',
  'followUpDateString',
  'surveyNoti',
  'statusSurveyNoti',
  'followUpType',
  'workingStatus',
  'statusPatient',
  'navigator',
  'navigatorTreatment',
  'navigatorSurvey',
  'navigatorDate',
  'moduleId',
  'recordDate',
];

export const WIDTH_A4 = 210;

export const HEIGHT_A4 = 297;

export const DEFAULT_COLOR_TAG = '#1976d2';

export const COLOR_STATUS_CHECKLIST: {
  [key: string]: { text: string; color: string };
} = {
  [ChecklistStatus.NotYetStarted]: { text: 'New', color: red[700] },
  [ChecklistStatus.Uncompleted]: {
    text: 'Started',
    color: yellow[600],
  },
  [ChecklistStatus.Completed]: { text: 'Completed', color: green[700] },
};

export const LIST_DATE_FORMAT: { [key: string]: string } = {
  [DateFormat.Iso8601]: 'YYYY-MM-DD',
  [DateFormat.IsoDmy]: 'DD-MM-YYYY',
  [DateFormat.IsoMdy]: 'MM-DD-YYYY',
  [DateFormat.Dmy]: 'DD/MM/YYYY',
  [DateFormat.Mdy]: 'MM/DD/YYYY',
};

export const LIST_CLEAR: { [key: string]: string[] } = {
  treatment: ['question', 'answer', 'type', 'stage'],
  survey: ['question', 'answer', 'dateAnswer'],
  question: ['answer', 'dateAnswer'],
  type: [
    'question',
    'answer',
    'stage',
    'dateAnswer',
    'stageDate',
    'assignDate',
  ],
  typeFilterPatient: ['phone', 'mrn', 'dob', 'email', 'lastName', 'firstName'],
  checklist: [
    'statusChecklist',
    'questionChecklist',
    'answerChecklist',
    'operatorChecklist',
  ],
  typeCheckList: [
    'statusChecklist',
    'questionChecklist',
    'answerChecklist',
    'operatorChecklist',
  ],
  questionChecklist: ['operatorChecklist', 'answerChecklist'],
  operatorChecklist: ['answerChecklist'],
};

export const CHANGING_BEHAVIOR: {
  [key: string]: { action: ChangingBehavior; text: string };
} = {
  copy: { action: ChangingBehavior.Copy, text: 'Copy' },
  ref: { action: ChangingBehavior.Ref, text: 'Make Reference' },
  move: { action: ChangingBehavior.Move, text: 'Move' },
};

export const COLOR_TREE: { [key: string]: { color: string; text: string } } = {
  folder: { color: grey[700], text: 'Folder' },
  treatment: { color: blue[700], text: 'Treatment' },
  survey: { color: green[500], text: 'Survey' },
  activatingChecklist: { color: pink['A400'], text: 'Attached Checklist' },
  checklist: { color: grey[500], text: 'Checklist' },
  referenceItem: { color: orange[500], text: 'Reference' },
};
export const OPTIONS_DATE_RANGE = [
  { value: 'allDates', title: 'All Dates' },
  { value: 'today', title: 'Today' },
  { value: 'lastWeek', title: 'Last Week' },
  { value: 'lastMonth', title: 'Last Month' },
  { value: 'last6Months', title: 'Last 6 Months' },
  { value: 'thisWeek', title: 'This Week' },
  { value: 'thisMonth', title: 'This Month' },
  { value: 'nextWeek', title: 'Next Week' },
  { value: 'nextMonth', title: 'Next Month' },
];

export const OPTION_DATE_FILTER = [
  { value: 'today', title: 'Today' },
  { value: 'nextWeek', title: 'Next Week' },
  { value: 'nextMonth', title: 'Next Month' },
];

export const OPTIONS_RECURRENCE = [
  { value: 'year', title: 'Year' },
  { value: 'month', title: 'Month' },
  { value: 'week', title: 'Week' },
  { value: 'day', title: 'Day' },
];

export const OPTIONS_DAY = [
  { value: 'Monday', title: 'Monday' },
  { value: 'Tuesday', title: 'Tuesday' },
  { value: 'Wednesday', title: 'Wednesday' },
  { value: 'Thursday', title: 'Thursday' },
  { value: 'Friday', title: 'Friday' },
  { value: 'Saturday', title: 'Saturday' },
  { value: 'Sunday', title: 'Sunday' },
];

export const OPTIONS_DATE_FOLLOW_UP = [
  { value: 'allDates', title: 'All Dates' },
  { value: 'today', title: 'Today' },
  { value: 'nextWeek', title: 'Next Week' },
  { value: 'nextMonth', title: 'Next Month' },
  { value: 'next3Month', title: 'Next 3 Months' },
  { value: 'next6Month', title: 'Next 6 Months' },
];

export const DEFAULT_VALUE_FILTER_PATIENT: { [key: string]: string } = {
  operatorChecklist: 'equal',
  dateAnswer: OPTIONS_DATE_RANGE[0].value,
  assignDate: OPTIONS_DATE_RANGE[0].value,
  stageDate: OPTIONS_DATE_RANGE[0].value,
};

export const SPECIFIED_TYPE: {
  [key: string]: { value: string; title: string };
} = {
  [SpecifiedType.Immediate]: { value: 'immediate', title: 'Immediate' },
  [SpecifiedType.NextMonths]: { value: 'nextMonth', title: 'Next month' },
  [SpecifiedType.NextWeek]: { value: 'nextWeek', title: 'Next week' },
  [SpecifiedType.NextHours]: { value: 'nextHours', title: 'Hour' },
};

export const COMPLETED_SURVEY_STATUS: {
  value: CompletedStatus | string;
  title: string;
}[] = [
    { value: 'incomplete', title: 'Incomplete' },
    { value: 'NOT_OPEN', title: 'Not Opened' },
    { value: CompletedStatus.Partial, title: 'Partially Complete' },
    { value: CompletedStatus.Totally, title: 'Totally Complete' },
  ];

export const LIST_FILTER_PATIENT: {
  [key: string]: { title: string; key: string };
} = {
  dob: { key: 'owner.dob', title: 'DOB' },
  email: { key: 'owner.email', title: 'Email' },
  firstName: { key: 'owner.firstName', title: 'First Name' },
  lastName: { key: 'owner.lastName', title: 'Last Name' },
  surveyCompleteDate: {
    key: 'updatedAt',
    title: 'Survey Complete Date',
  },
  createdAt: { key: 'createdAt', title: 'Survey Start Date' },
  followUpDate: { key: 'followUps.followUpDate', title: 'Task Date' },
};

export const LIST_SHOW_ITEM_PATIENT_CARD = [
  { value: 'followUp', label: 'Task' },
  { value: 'note', label: 'Note' },
  { value: 'surveyResult', label: 'Survey Result' },
  { value: 'checklist', label: 'Checklist' },
  { value: 'condition', label: 'Condition' },
  { value: 'timeRecord', label: 'Time Record' },
];

export const FILTER_TREATMENT = [
  { value: 'all', label: 'All Treatments' },
  { value: 'default', label: 'Default Treatment' },
  { value: 'homepage', label: 'Homepage' },
  { value: 'favorite', label: 'My Favorites' },
  { value: 'myTreatment', label: 'My Treatments' },
  { value: 'public', label: 'Public Treatments' },
  { value: 'private', label: 'Private Treatments' },
  { value: 'osc', label: 'OSC Procedures Treatments' }
];

export enum TaskTypeFilter {
  all = 'all',
  favorite = 'favorite',
}

export const LIST_FILTER_TASK_TYPE = [
  { value: TaskTypeFilter.all, label: 'All' },
  { value: TaskTypeFilter.favorite, label: 'Favorite' },
];

export const WORKING_STATUS: {
  value: SurveyResultStatus;
  title: string;
}[] = [
    { value: SurveyResultStatus.New, title: 'New' },
    { value: SurveyResultStatus.Review, title: 'Reviewed' },
  ];

export const HOME_PAGE_CONFIG: {
  value: HomePageConfig;
  title: string;
  checked?: boolean;
}[] = [
    { value: HomePageConfig.Home, title: 'Homepage' },
    { value: HomePageConfig.AllTreatment, title: 'All Treatments' },
    {
      value: HomePageConfig.MyFavoriteTreatments,
      title: 'My Favorite Treatments',
    },
    { value: HomePageConfig.MyTreatment, title: 'My Treatments' },
    { value: HomePageConfig.PublicTreatments, title: 'Public Treatments' },
    { value: HomePageConfig.PrivateTreatments, title: 'Private Treatments' },
  ];

export const ANALYTIC_DASHBOARD_CONFIG: {
  value: ConfigAnalyticData;
  title: string;
}[] = [
    { value: ConfigAnalyticData.LastWeek, title: 'Last Week' },
    {
      value: ConfigAnalyticData.LastMonth,
      title: 'Last Month',
    },
    { value: ConfigAnalyticData.LastSixMonths, title: 'Last 6 Months' },
  ];

export const TYPE_TASK_TYPE: { [key: string]: string } = {
  all: 'All Treatments',
  associated: 'Associated Treatment',
  folder: 'Folder',
};

export const LIST_GENDER: {
  [key: string]: string;
} = {
  [Gender.Female]: 'Female',
  [Gender.Male]: 'Male',
  [Gender.Other]: 'Other',
};

export const CONFIRM_COLOR: {
  [key: string]: string;
} = {
  update: blue[700],
  warning: yellow[700],
  error: red[700],
};

export const CONFIRM_BACKGROUND_COLOR: {
  [key: string]: string;
} = {
  update: '#D1E1F1',
  warning: '#faf0d4',
  error: '#F4E0E0',
};

export const LIST_RACE: {
  [key: string]: string;
} = {
  [Race.Other]: 'Other',
  [Race.Asia]: 'Asia',
  [Race.Black]: 'Black',
  [Race.NativeHawaiianOrOtherPacificIslander]:
    'Native Hawaiian or Other Pacific Islander',
  [Race.NativeIndianOrAlaskaNative]: 'Native Indian or Alaska Native',
  [Race.White]: 'White',
};

export const TIME_FRAME = ['Daily', 'Weekly', 'Monthly', 'Six months'];
export const TYPE_SEND_MESSAGE = [{ value: 'sendSms', label: 'Send SMS' }];

export const DATE_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

export const DATE_TIME_REGEX = /(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#^&!%*?=-])[A-Za-z\d@$#^&!%*?=-]{8,}$/;

export const NO_SPACING_REGEX = /^[^\s]+(\s+[^\s]+)*$/;

export const CPT_CODE_REGEX = /^[a-zA-Z0-9]+$/;

export const NAME_OF_VALUE_MORE: { [key: string]: string } = {
  [DataSource.Comment]: 'Number of replies',
  [DataSource.Patient]: 'From SMS',
};

export const RULES: { [key: string]: { [key: string]: string | undefined } } = {
  patient: {
    lastName: '',
    dob: '',
    mrn: '',
    email: '',
    phone: '',
    typeFilterPatient: 'firstName',
    operatorPatient: 'equal',
    statusPatient: '',
  },
  followUp: {
    status: 'all',
    followUpDate: OPTIONS_DATE_RANGE[0].value,
    followUpType: '',
    followUpTypeDescp: '',
  },
  treatment: {
    treatment: '',
    survey: 'all',
    question: '',
    answer: '',
    operator: 'equal',
    type: '',
    stage: '',
    assignStatus: 'OPEN',
    assignDate: OPTIONS_DATE_RANGE[0].value,
    stageDate: OPTIONS_DATE_RANGE[0].value,
    dateAnswer: OPTIONS_DATE_RANGE[0].value,
    dateSurvey: OPTIONS_DATE_RANGE[0].value,
  },
  group: {
    condition: 'and',
  },
  tag: {
    tag: undefined,
  },
  checklist: {
    checklist: '',
    typeCheckList: 'status',
    statusChecklist: '',
    questionChecklist: '',
    operatorChecklist: 'equal',
    answerChecklist: '',
  },
  survey: {
    surveyNoti: '',
    statusSurveyNoti: 'all',
    dateRangeSurveyNoti: OPTIONS_DATE_RANGE[0].value,
    workingStatus: 'all',
  },
  navigator: {
    navigator: '',
    navigatorTreatment: '',
    navigatorDate: OPTIONS_DATE_RANGE[0].value,
    navigatorSurvey: '',
  },
  module: {
    moduleId: '',
    recordDate: OPTIONS_DATE_RANGE[0].value,
  },
};

export const USER_STATUS: { [key: string]: string } = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Inactive]: 'Inactive',
};

export const PASSWORD_RULE = [
  {
    title: 'Use 8 or more characters',
  },
  {
    title: 'Include at least one lowercase (a-z) and one uppercase (A-Z)',
  },
  {
    title: 'Include at least one special character (e.g. !@#$&)',
  },
  {
    title:
      'Does not contain blank spaces or the following special characters: <>,',
  },
  {
    title: 'Include at least one digit (0-9)',
  },
];

export const ASSIGN_TREATMENT_MESSAGE =
  'Hello {{firstName}}, this is the link from {{orgName}} regarding your treatment information. {{link}}';
export const SEND_SURVEY_MESSAGE =
  'Hello {{firstName}}, it is time for your health check in with {{orgName}}. {{link}}';

export const COLOR_BY_TYPE: { [key: string]: string } = {
  [TreatmentType.Treatment]: blue[700],
  [TreatmentType.Survey]: green[700],
  [TreatmentType.Folder]: grey[700],
};

export const GET_FREQ: {
  [key: number]: string;
} = {
  0: 'year',
  1: 'month',
  2: 'week',
  3: 'day',
};

export const GET_REPEAT_WEEK: {
  [key: number]: { title: string; weekday: number };
} = {
  0: { title: 'Monday', weekday: 6 },
  1: { title: 'Tuesday', weekday: 0 },
  2: { title: 'Wednesday', weekday: 1 },
  3: { title: 'Thursday', weekday: 2 },
  4: { title: 'Friday', weekday: 3 },
  5: { title: 'Saturday', weekday: 4 },
  6: { title: 'Sunday', weekday: 5 },
};

export const FILTER_BILLING_TYPE = [
  { label: 'Most recent month', value: 'lastMonth' },
  { label: 'Last Six Months', value: 'lastSixMonths' },
];

export const MIN_WIDTH_TABLE = 750;

export const DEFAULT_LIMIT = 25;
export const COUNT_DOWN_TIME = 600;

export const FILTER_CONFIGS = [
  {
    title: 'Navigator Status',
    value: 'Navigator Status',
    queryPath: 'navigator.status',
  },
  {
    title: 'Patient Status',
    value: 'Patient Status',
    queryPath: 'patient.status',
  },
  {
    title: 'Patient DoB',
    value: 'Patient DoB',
    queryPath: 'patient.dateOfBirth',
  },
  {
    title: 'Patient Email',
    value: 'Patient Email',
    queryPath: 'patient.email',
  },
  {
    title: 'Patient First Name',
    value: 'Patient First Name',
    queryPath: 'patient.firstName',
  },
  {
    title: 'Patient Last Name',
    value: 'Patient Last Name',
    queryPath: 'patient.lastName',
  },
  { title: 'Patient MRN', value: 'Patient MRN', queryPath: 'patient.mrn' },
  {
    title: 'Patient Phone',
    value: 'Patient Phone',
    queryPath: 'patient.phone',
  },
  {
    title: 'Survey Date Range',
    value: 'Survey Date Range',
    queryPath: 'survey.range',
  },
  { title: 'Survey Name', value: 'Survey Name', queryPath: 'survey.id' },
  { title: 'Tag Name', value: 'Tag Name', queryPath: 'tag.name' },
  {
    title: 'Task Date Range',
    value: 'Task Date Range',
    queryPath: 'task.tasks.followupdate',
  },
  {
    title: 'Task Description',
    value: 'Task Description',
    queryPath: 'task.note',
  },
  { title: 'Task Status', value: 'Task Status', queryPath: 'task.status' },
  { title: 'Task Type', value: 'Task Type', queryPath: 'task.type.name' },
  {
    title: 'Treatment Name',
    value: 'Treatment Name',
    queryPath: 'treatment.id',
  },
  {
    title: 'Treatment Sending Date Range',
    value: 'Treatment Sending Date Range',
    queryPath: 'treatment.range',
  },
  {
    title: 'Treatment Status',
    value: 'Treatment Status',
    queryPath: 'treatment.status',
  },
  {
    title: 'Treatment Type',
    value: 'Treatment Type',
    queryPath: 'treatment.type',
  },
];

export const SCORING_METRICS: {
  [key: string]: { name: string; description: string };
} = {
  dalleChallScore: {
    name: 'DALL-E Challenge Score',
    description:
      'This score represents how challenging an image generation task is for the DALL-E model. A higher score indicates a more complex task requiring advanced understanding and generation capabilities.',
  },
  autoreadabilityScore: {
    name: 'Auto Readability Score',
    description:
      'A comprehensive metric assessing the readability of text. It evaluates sentence structure, vocabulary complexity, and overall readability. Lower scores suggest easier text, while higher scores indicate more complex language usage.',
  },
  smogScore: {
    name: 'SMOG Score',
    description:
      'Stands for Simple Measure of Gobbledygook. It estimates the years of education one needs to understand a piece of writing. The higher the score, the more complex the text, typically requiring more education to understand it.',
  },
  freGrade: {
    name: 'Flesch Reading Ease Grade',
    description:
      'A readability test designed to indicate comprehension difficulty when reading a text. It is based on the length of words and sentences. Higher scores indicate material that is easier to read, with scores typically ranging from 0 to 100.',
  },
  collieu: {
    name: 'Coleman-Liau Index',
    description:
      'An index formulated to gauge the readability of English text by calculating the average number of letters per 100 words and the average number of sentences per 100 words. It predicts the U.S. grade level needed to comprehend the text. Higher scores denote more advanced texts.',
  },
  linearWrite: {
    name: 'Linear Write Score',
    description:
      'A metric assessing the linearity and clarity of writing. It considers the logical flow and structure of text, ensuring it progresses in a clear, direct manner. Higher scores indicate better organization and clarity.',
  },
  gunningFog: {
    name: 'Gunning Fog Index',
    description:
      'This index measures the readability of English writing. It calculates the grade level required to grasp the text, factoring in word complexity and sentence length. Higher scores suggest that the text is more difficult to understand.',
  },
  polySyllable: {
    name: 'Polysyllable Count',
    description:
      'Counts the number of words in the text that have three or more syllables. This metric helps in assessing the complexity of the vocabulary used in the text. A higher count usually indicates a more complex text.',
  },
  fleshGrade: {
    name: 'Flesch-Kincaid Grade',
    description:
      'This grade level formula measures how difficult a text is to understand. It calculates the U.S. school grade level needed for comprehension based on the average number of syllables per word and words per sentence. Higher grades indicate more difficult text.',
  },
  __typename: {
    name: 'Type Name',
    description:
      'A label used in programming to identify the type of a data structure, particularly in contexts where multiple types may be present. It helps in managing and organizing data efficiently.',
  },
};

export const LIST_CONVERSION_TYPE: { [key: string]: string } = {
  [ConversionType.Batch]: 'Batch',
  [ConversionType.Online]: 'Online',
};

export const LIST_CONVERSION_STATUS: { [key: string]: string } = {
  [ConversionStatus.Done]: 'Done',
  [ConversionStatus.Failed]: 'Failed',
  [ConversionStatus.InProcess]: 'In Process',
};

export const LIST_FORM_STATUS: { [key: string]: string } = {
  [FormStatus.New]: 'New',
  [FormStatus.Reviewed]: 'Reviewed',
  [FormStatus.Deleted]: 'Deleted',
};

export const PROCEDURES = [
  {
    name: 'Cystoscopy',
    info: 'Cysto OSC',
  },
  {
    name: 'Prostate Biopsy',
    info: 'TRUS/biopsy 15 or 30',
  },
  {
    name: 'Cystoscopy with Stent Removal',
    info: 'Cysto Stent Removal OSC',
  },
  {
    name: 'Bulkamid',
    info: 'Bulkamid',
  },
  {
    name: 'Botox',
    info: 'Botox 15 or 30',
  },
];